import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import ImpressumSection from '../ImpressumSection/ImpressumOne'
import DownloadSection from '../DownloadSection/Download';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';

class Impressum extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={""} />
                    <Breadcrumb title="Impressum" />
                    <ImpressumSection />
                    <DownloadSection />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default Impressum;