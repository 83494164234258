import * as React from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = () => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div className="container">
        <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-7">
        <div className="section-heading text-center">
            <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                <i className="far fa-lightbulb text-primary mr-1" />
                <span className="text-primary"> Slideshow </span> 
            </span>
            <h2> Gerätemanagement </h2>
            <p className="d-none d-sm-block mt-4"> Neue Geräte und Businesslogik können kinderleicht selbst eingebunden werden </p>
            <p className="d-block d-sm-none mt-4"> kinderleichtes Einbinden neuer Geräte</p>
        </div>
                        
        <section  id="slideshow" className="section faq-area style-two ptb_100">
        <div className="row">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.js">
            <div
                style={{
                    height: '750px',
                    width: '900px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <Viewer
                    fileUrl="img/Photostrory.pdf"
                    plugins={[
                       // defaultLayoutPluginInstance,
                    ]}
                />
            </div>
        </Worker>
        </div>
        </section>
        </div>
        </div>
        </div>
    );
};

export default PdfViewer;